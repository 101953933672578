/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import {
	ActionsPageModel,
	ActionVO,
	BarChartDataVO,
	ListResponse,
	OptionVO,
	ReportFilterWithExperimentKeysVO,
	ReportFilterWithTransitionVO,
	RollupVO,
} from 'ui-api';
import { BarChartMetric, Bars, Colors, metrics } from '@steadybit/ui-components-lib';
import { getColorForState } from 'pages/experiments/components/utils';
import { ChartType, Time } from 'pages/settings/Reporting/urlParams';
import { toTitleCase } from 'utils/string';
import { days } from 'utils/time';
import axios from 'axios';

import { ActionsApi } from './actionsApi';

export interface ReportingResponse extends ActionsPageModel<Bars> {
	rollup: RollupVO;
	content: Bars[];
}

interface ReportingResponseFromBackend extends ActionsPageModel<BarChartDataVO> {
	content: BarChartDataVO[];
}

export class ReportingApi {
	private actionApi: ActionsApi;

	constructor(actionApi: ActionsApi) {
		this.actionApi = actionApi;
	}

	public async getExecutions({
		environmentIds = [],
		teamIds = [],
		type,
		time,
	}: {
		time: Time;
		environmentIds?: string[];
		teamIds?: string[];
		type: ChartType;
	}): Promise<ReportingResponse> {
		let rollup: RollupVO = 'MONTHLY';

		// default: all time
		let from: number | undefined = undefined;
		let to: number = new Date().getTime();

		// custom
		if (Array.isArray(time)) {
			from = time[0];
			to = time[1];
			rollup = to - from < days(31).getMillis() ? 'DAILY' : 'MONTHLY';
		}

		// last 30 days
		if (time === 'last30Days') {
			const today = new Date();
			to = today.getTime();
			from = to - days(30).getMillis();
			rollup = 'DAILY';
		}

		let body: ReportFilterWithExperimentKeysVO = {
			to: new Date(to),
			teamIds,
			environmentIds,
			rollup,
		};

		if (from) {
			body.from = new Date(from);
		}

		const typeToPath: Record<ChartType, string> = {
			EXPERIMENT_NOTHING: 'experiments/reports/creations',
			EXPERIMENT_CREATED: 'experiments/reports/creations/created-via',
			EXPERIMENT_ORIGIN: 'experiments/reports/creations/origin',
			EXECUTION_NOTHING: 'experiments/reports/executions',
			EXECUTION_ATTACK: 'experiments/reports/executions/steps/attacks',
			EXECUTION_STATE: 'experiments/reports/executions/state',
			EXECUTION_TRIGGER: 'experiments/reports/executions/trigger',
			EXECUTION_TRANSITION_FC: 'experiments/reports/executions/transitions',
			EXECUTION_TRANSITION_CF: 'experiments/reports/executions/transitions',
			EXECUTION_COMPLETED_VS_FAILED: 'experiments/reports/executions/state',
			EXECUTION_BASIC_RELIABILITY_SCORE: 'experiments/reports/executions/state',
			TEAMS: 'reports/teams',
			USERS: 'reports/users',
			ENVIRONMENTS: 'reports/environments',
		};

		if (type === 'EXECUTION_TRANSITION_FC') {
			const withTransition: ReportFilterWithTransitionVO = {
				...body,
				fromStates: ['FAILED'],
				toStates: ['COMPLETED'],
			};
			body = withTransition;
		} else if (type === 'EXECUTION_TRANSITION_CF') {
			const withTransition: ReportFilterWithTransitionVO = {
				...body,
				fromStates: ['COMPLETED'],
				toStates: ['FAILED'],
			};
			body = withTransition;
		}

		const result = (await axios.post<ReportingResponseFromBackend>(`/ui/${typeToPath[type]}`, body)).data;
		const content = postProcessData(result.content, type);

		// the reliability score is a cumulative value, so we need to calculate it
		// to do that, we first need all values before the given frame and then add the current values to it
		if (type === 'EXECUTION_BASIC_RELIABILITY_SCORE') {
			let startScore = 0;
			if (body.from) {
				const beforeValue = (
					await axios.post<ReportingResponseFromBackend>(`/ui/${typeToPath[type]}`, {
						...body,
						to: new Date(body.from.getTime() - days(1).getMillis()),
						from: undefined,
						rollup: 'MONTHLY',
					})
				).data;

				const allCompleted = beforeValue.content.reduce((acc, { values }) => acc + (values.COMPLETED || 0), 0);
				const allFailed = beforeValue.content.reduce((acc, { values }) => acc + (values.FAILED || 0), 0);
				startScore = allCompleted - allFailed;
			}

			content.forEach((data, i) => {
				const valueBefore = i === 0 ? startScore : content[i - 1].metrics[0].value;
				data.metrics[0].value += valueBefore;
			});
		}

		if (type === 'EXECUTION_ATTACK') {
			const actions: ActionVO[] = await this.actionApi.fetchActions(true);

			const visitedActions = new Set<string>();
			const nonUniqueActionNames = new Set<string>();
			for (let iA = 0; iA < actions.length; iA++) {
				const action = actions[iA];
				if (visitedActions.has(action.name)) {
					nonUniqueActionNames.add(action.name);
				}
				visitedActions.add(action.name);
			}
			const labelMap = new Map<string, string>();
			for (let iA = 0; iA < actions.length; iA++) {
				const action = actions[iA];
				if (nonUniqueActionNames.has(action.name)) {
					labelMap.set(action.id, `${action.name} (${action.technology})`);
				}
			}

			for (let iB = 0; iB < content.length; iB++) {
				const bar = content[iB];
				for (let iM = 0; iM < bar.metrics.length; iM++) {
					const metric: BarChartMetric = bar.metrics[iM];
					const matchingAction = actions.find((action) => action.id === metric.label);
					if (matchingAction) {
						const { name, technology, category } = matchingAction;
						metric.label = labelMap.get(matchingAction.id) || name;
						metric.data = {
							technology: technology || '',
							category: category || '',
						};
					}
				}
			}
		}

		return {
			...result,
			rollup,
			content,
		};
	}

	public async getExecutionEnvironments(): Promise<ListResponse<OptionVO>> {
		return (await axios.get<ListResponse<OptionVO>>('/ui/experiments/reports/executions/values/environments')).data;
	}
	public async getExecutionTeams(): Promise<ListResponse<OptionVO>> {
		return (await axios.get<ListResponse<OptionVO>>('/ui/experiments/reports/executions/values/teams')).data;
	}
	public async getExperimentEnvironments(): Promise<ListResponse<OptionVO>> {
		return (await axios.get<ListResponse<OptionVO>>('/ui/experiments/reports/creations/values/environments')).data;
	}
	public async getExperimentTeams(): Promise<ListResponse<OptionVO>> {
		return (await axios.get<ListResponse<OptionVO>>('/ui/experiments/reports/creations/values/teams')).data;
	}
}

function postProcessData(data: BarChartDataVO[], type: ChartType): Bars[] {
	const colors = new Map<string, string>();
	if (
		type === 'EXECUTION_STATE' ||
		type === 'EXECUTION_COMPLETED_VS_FAILED' ||
		type === 'EXECUTION_BASIC_RELIABILITY_SCORE'
	) {
		colors.set('Created', getColorForState('CREATED'));
		colors.set('Prepared', getColorForState('PREPARED'));
		colors.set('Running', getColorForState('RUNNING'));
		colors.set('Failed', getColorForState('FAILED'));
		colors.set('Completed', getColorForState('COMPLETED', Colors.feedbackSuccess));
		colors.set('Canceled', Colors.neutral300);
		colors.set('Skipped', getColorForState('SKIPPED'));
		colors.set('Errored', getColorForState('ERRORED'));

		if (type === 'EXECUTION_COMPLETED_VS_FAILED') {
			data = data.map((bucket) => {
				const completed = bucket.values.COMPLETED || 0;
				const failed = bucket.values.FAILED || 0;
				const total = completed + failed;
				if (total > 0) {
					const COMPLETED = ((completed / total) * 100) | 0;
					bucket.values = {
						// values in percent
						COMPLETED,
						FAILED: 100 - COMPLETED,
					};
				} else {
					bucket.values = {};
				}
				return bucket;
			});
		}

		if (type === 'EXECUTION_BASIC_RELIABILITY_SCORE') {
			data = data.map((bucket) => {
				const completed = bucket.values.COMPLETED || 0;
				const failed = bucket.values.FAILED || 0;
				bucket.values = {
					SCORE: completed - failed,
				};
				return bucket;
			});
		}
	}

	if (type === 'EXECUTION_BASIC_RELIABILITY_SCORE') {
		return data.map(({ date, values }) => ({
			domain: String(date),
			metrics: Object.entries(values).map(([, value]) => ({
				label: 'Basic Reliability Score',
				value,
				color: metrics.BarChartColorTheme[0],
			})),
		}));
	}

	let colorIndex = 0;

	return data.map(({ date, values }) => {
		const domain = String(date);
		return {
			domain,
			metrics: Object.entries(values)
				.map(([key, value]) => {
					const resolvedLabel = key === '*' ? '' : resolveLabel(key, type);
					if (!colors.has(resolvedLabel)) {
						colors.set(resolvedLabel, metrics.BarChartColorTheme[colorIndex++ % metrics.BarChartColorTheme.length]);
					}
					return { label: resolvedLabel, value, color: colors.get(resolvedLabel) };
				})
				.filter(({ value }) => value > 0),
		};
	});
}

function resolveLabel(label: string, type: ChartType): string {
	if (type === 'EXECUTION_TRIGGER') {
		return TRIGGER_LABELS.get(label) || toTitleCase(label.toLocaleLowerCase());
	}
	if (type === 'EXECUTION_STATE' || type === 'EXECUTION_COMPLETED_VS_FAILED' || type === 'EXPERIMENT_CREATED') {
		if (label === 'UI') {
			return 'UI';
		}
		return toTitleCase(label.toLocaleLowerCase());
	}
	if (type === 'EXPERIMENT_ORIGIN') {
		return ORIGIN_LABELS.get(label) || toTitleCase(label.toLocaleLowerCase());
	}
	return label;
}

const TRIGGER_LABELS = new Map<string, string>(
	Object.entries({
		API: 'API',
		CLI: 'CLI',
		UI: 'UI',
	}),
);

const ORIGIN_LABELS = new Map<string, string>(
	Object.entries({
		FROM_SCRATCH: 'From scratch',
	}),
);
