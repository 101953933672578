/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { presets, UnitFieldUnit } from '@steadybit/ui-components-lib';
import { ReactElement, ReactNode } from 'react';

export function ControlWrapper({ children }: { children: ReactNode }): ReactElement {
	return (
		<div
			style={{
				display: 'flex',
				width: '100%',
				alignItems: 'center',
			}}
		>
			{children}
		</div>
	);
}

interface OptionsProps {
	onChange: (v: string) => void;
	options: UnitFieldUnit[];
	width?: string | number;
	disabled?: boolean;
	value: string;
}

export function Options({ value, options, disabled, width, onChange }: OptionsProps): ReactElement {
	return (
		<presets.dropdown.SingleChoiceButton
			placement="bottom-start"
			maxContentHeight="300px"
			withKeyboardNavigation
			disabled={disabled}
			selectedId={value}
			items={options}
			onSelect={onChange}
			style={{
				width,
				borderTopLeftRadius: '0',
				borderBottomLeftRadius: '0',
			}}
		>
			{options.find((o) => o.id === value)?.label ?? value}
		</presets.dropdown.SingleChoiceButton>
	);
}
