/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useField } from 'formik';

import { useEditorSettings } from './useEditorSettings';

export default function useIsExperimentDisabled(): boolean {
	const { mode } = useEditorSettings();
	const [{ value: actions }] = useField<string[]>({ name: 'actions' });

	if (mode === 'experiment') {
		const canRunByTeam = actions.includes('run-by-team');
		const canRunByLicense = actions.includes('run-by-license');
		return !actions?.includes('save') || !canRunByTeam || !canRunByLicense;
	}

	return !actions?.includes('save');
}
