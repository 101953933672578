/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Container,
	FormikCheckbox,
	FormikTextField,
	Link,
	ModalContentV2,
	ModalHeaderV2,
	ModalV2,
	Snackbar,
	Stack,
	Text,
} from 'components';
import TimezoneSelection from 'components/Select/Dropdown/presets/TimezoneSelection';
import { Button, Flex, presets } from '@steadybit/ui-components-lib';
import { Form, Formik, FormikHelpers, useField } from 'formik';
import { UpdateCurrentUserRequest } from 'ui-api';
import { ValidationError } from 'utils/error';
import { Services } from 'services/services';
import React, { ReactElement } from 'react';
import { useUser } from 'services/usersApi';

const jobRoles = [
	{ id: 'DEVELOPER', label: 'Developer' },
	{ id: 'SRE', label: 'SRE' },
	{ id: 'PERFORMANCE_QA_ENGINEER', label: 'Performance/QA Engineer' },
	{ id: 'MANAGER', label: 'Manager' },
	{ id: 'OTHER', label: 'Other' },
	{ id: 'PREFER_NOT_TO_SAY', label: 'Prefer not to say' },
];

type AccountSettingsFormValues = UpdateCurrentUserRequest;

interface AccountSettingsProps {
	withName?: boolean;
	withEmail?: boolean;
	withJobRole?: boolean;
	onClose?: () => void;
}

export default function AccountSettings({
	onClose,
	withName = true,
	withEmail = true,
	withJobRole = true,
}: AccountSettingsProps): ReactElement {
	const user = useUser();
	const [submitError, setSubmitError] = React.useState<Error | null>();

	const initial: AccountSettingsFormValues = user ?? {
		name: '',
		jobRole: '',
		termsAccepted: false,
		mainNavigationAlwaysExpanded: false,
	};

	const handleSubmit = async (
		{ ...values }: AccountSettingsFormValues,
		form: FormikHelpers<AccountSettingsFormValues>,
	): Promise<void> => {
		try {
			setSubmitError(null);
			await Services.users.updateCurrentUser({
				...values,
				termsAccepted: true,
			});
			Snackbar.dark('User updated.', { toastId: 'user-updated' });

			return new Promise((resolve) => {
				const prev = window.onload;
				window.onload = () => {
					resolve();
					window.onload = prev;
				};
				window.location.reload();
			});
		} catch (error) {
			if (error instanceof ValidationError) {
				error.violations.forEach((violation) => form.setFieldError(violation.field, violation.message));
			} else {
				setSubmitError(error);
			}
		}
	};

	return (
		<Formik initialValues={initial} enableReinitialize onSubmit={handleSubmit}>
			{({ isSubmitting, values, setFieldValue }) => (
				<Form noValidate>
					<ModalV2 slick width={550}>
						<ModalHeaderV2 title="Account Settings" onClose={onClose} />
						<ModalContentV2>
							<Stack size={'medium'}>
								{withName && (
									<Stack size={'xxSmall'}>
										<FormikTextField
											label={'Your Name'}
											name={'name'}
											maxLength={255}
											autoComplete={'off'}
											data-private
											autoFocus
										/>
										<Text variant={'small'} muted>
											Your name is visible to other users.
										</Text>
									</Stack>
								)}

								{withJobRole && <JobRole />}

								{user.email && withEmail && (
									<Stack size={'xxSmall'}>
										<FormikTextField
											label={'Your E-Mail'}
											name={'email'}
											maxLength={255}
											autoComplete={'off'}
											disabled
											data-private
										/>
										<Text variant={'small'} muted>
											Managed by your administrator
										</Text>
									</Stack>
								)}

								<Stack size={'xxSmall'} alignItems="flex-end" width="100%">
									<Timezone />
									{values.timeZone && (
										<Button type="chromeless" onClick={() => setFieldValue('timeZone', undefined)}>
											Reset to browser default
										</Button>
									)}
								</Stack>
								<Stack size={'xxSmall'}>
									<FormikCheckbox
										label={'Main navigation always expanded'}
										name={'mainNavigationAlwaysExpanded'}
										autoComplete={'off'}
									/>
									<Text variant={'small'} muted>
										This can be useful if you are using a wide screen.
									</Text>
								</Stack>

								{!user.termsAccepted && (
									<Stack size={'xxSmall'}>
										<FormikCheckbox
											mr={'small'}
											name="termsAccepted"
											label={
												<Flex direction="horizontal" align="center" spacing="xxSmall">
													<span>Accept Data Privacy Terms</span>
													{user.previousTermsAccepted && <presets.pill.Tag small>new version</presets.pill.Tag>}
												</Flex>
											}
										/>
										<Text ml={'medium'} variant={'small'} muted>
											{user.previousTermsAccepted ? (
												<>
													The{' '}
													<Link href="https://www.steadybit.com/imprint-and-legal" external>
														data privacy terms
													</Link>{' '}
													have changed. You need to accept them in order to continue to use Steadybit.
												</>
											) : (
												<>
													Agree to Steadybit&apos;s{' '}
													<Link href="https://www.steadybit.com/imprint-and-legal" external>
														data privacy terms
													</Link>
													.
												</>
											)}
										</Text>
									</Stack>
								)}

								<Container>
									<Button type="primary" size="large" submit disabled={!values.termsAccepted || isSubmitting}>
										Save
									</Button>
									{submitError ? <Text color={'coral'}> {submitError.toString()}</Text> : null}
								</Container>
							</Stack>
						</ModalContentV2>
					</ModalV2>
				</Form>
			)}
		</Formik>
	);
}

function JobRole(): ReactElement {
	const [{ value }, , { setValue }] = useField('jobRole');
	return (
		<Flex spacing="xSmall" style={{ width: '100%' }}>
			<Text variant="mediumStrong">Your job role</Text>

			<presets.dropdown.SingleChoiceButton
				withKeyboardNavigation
				selectedId={value}
				items={jobRoles}
				onSelect={setValue}
				style={{ width: '100%' }}
			>
				{jobRoles.find((role) => role.id === value)?.label || 'Your job role'}
			</presets.dropdown.SingleChoiceButton>
		</Flex>
	);
}

function Timezone(): ReactElement {
	const [{ value }, , { setValue }] = useField('timeZone');
	return (
		<Flex spacing="xSmall" style={{ width: '100%' }}>
			<Text variant="mediumStrong">Timezone</Text>
			<TimezoneSelection selectedTimezone={value} selectTimezone={setValue} />
		</Flex>
	);
}
