/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	BasicListItem,
	Button,
	Dropdown,
	Flex,
	IconButton,
	IconType,
	ItemRenderer,
	presets,
	Ul,
} from '@steadybit/ui-components-lib';
import {
	duplicateExperimentConfirm,
	DuplicateExperimentResult,
} from 'pages/experiments/components/DuplicateExperimentConfirm';
import { ExperimentSubHeaderEnvironments } from 'pages/experiments/components/header/experimentSubHeaderEnvironments';
import ExperimentSubHeaderHypothesis from 'pages/experiments/components/header/ExperimentSubHeaderHypothesisDropdown';
import EditSchedulesModal from 'pages/experiments/components/Schedule/EditSchedulesModal/EditSchedulesModal';
import ExperimentBadgeModal from 'pages/experiments/components/header/ExperimentBadgeModal';
import ExperimentFileModal from 'pages/experiments/components/header/ExperimentFileModal';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { Snackbar, Stack, Subnav, Tooltip, userConfirm } from 'components';
import LicenseTooltipContent from 'App/components/LicenseTooltipContent';
import { prepareCopyExperimentForm } from 'pages/experiments/experiment';
import useGlobalPermissions from 'services/useGlobalPermissions';
import { ReactElement, useState } from 'react';
import { Services } from 'services/services';
import { useFormikContext } from 'formik';
import { theme } from 'styles.v2/theme';
import { useHistory } from 'url/hooks';

import useIsExperimentDisabled from './useIsExperimentDisabled';
import { saveExperiment } from './ExperimentEditorFormHandler';
import { useLicenseFeature } from '../../services/licenseApi';
import { ExperimentFormValues } from './types';
import ExperimentTags from './ExperimentTags';

export default function ExperimentSubHeader(): ReactElement {
	const { values, touched, submitForm, setValues, setTouched, isSubmitting } = useFormikContext<ExperimentFormValues>();

	const { experimentKey, name, actions } = values;

	const isNewExperiment = !experimentKey || experimentKey === '<new>';

	const disabled = useIsExperimentDisabled();
	const isEditPermitted = !disabled;

	const history = useHistory();
	const [showDownloadModal, setShowDownloadModal] = useState(false);
	const [showScheduleModal, setShowScheduleModal] = useState(false);
	const [showBadgeModal, setShowBadgeModal] = useState(false);

	const templatesEnabled = useLicenseFeature('TEMPLATES');
	const permissions = useGlobalPermissions();
	const canCreateTemplates = permissions.templates.canCreate;

	const dirty = Object.keys(touched).length > 0;
	const canSaveAsTemplate = canCreateTemplates && isEditPermitted && !isNewExperiment;
	const canSaveExperiment = canSaveAsTemplate || (isEditPermitted && (isNewExperiment || dirty));

	const schedulesResult = Services.schedulesApi.useSchedules$(experimentKey);
	const schedules = schedulesResult.value?.content || [];

	return (
		<Subnav
			px="medium"
			maxWidth="100%"
			sx={{ boxShadow: 'applicationSmall', zIndex: 3 }}
			stackProps={{ justifyContent: 'space-between' }}
		>
			{showDownloadModal && (
				<ExperimentFileModal
					title={`experiment-${experimentKey}`}
					experimentKey={experimentKey}
					onClose={() => setShowDownloadModal(false)}
				/>
			)}
			{showBadgeModal && (
				<ExperimentBadgeModal experimentKey={experimentKey} onClose={() => setShowBadgeModal(false)} />
			)}
			{showScheduleModal && (
				<EditSchedulesModal
					experimentKey={experimentKey}
					experimentName={values.name}
					onClose={() => setShowScheduleModal(false)}
				/>
			)}

			<Stack
				justifyContent="flex-start"
				direction="horizontal"
				minWidth="fit-content"
				alignItems="center"
				overflowX="hidden"
				py="2px"
				flexGrow={1}
			>
				<ExperimentSubHeaderEnvironments disabled={disabled} />
				<Divider />
				<ExperimentSubHeaderHypothesis disabled={disabled} />
				<Divider />
				<ExperimentTags disabled={disabled} />
			</Stack>

			<Dropdown
				placement="bottom-end"
				renderDropdownContent={({ close }) => (
					<presets.dropdown.DropdownContentFrame>
						<Ul<ContextMenuItem, AdditionalProps>
							items={
								[
									!isNewExperiment
										? {
												id: '1',
												icon: 'duplicate',
												label: 'Duplicate',
												onClick: () => {
													onDuplicate();
													close();
												},
											}
										: null,
									!isNewExperiment
										? {
												id: '2',
												icon: 'file',
												label: 'View as JSON',
												onClick: () => {
													onDownload();
													close();
												},
											}
										: null,
									!isNewExperiment
										? {
												id: '3',
												icon: 'badge',
												label: 'Create badge',
												onClick: () => {
													onBadge();
													close();
												},
											}
										: null,
									!isNewExperiment
										? {
												id: '4',
												icon: 'calendar',
												label:
													schedules.length > 0
														? permissions.schedules.canEdit
															? 'Edit Schedules'
															: 'View Schedules'
														: 'Schedule run',
												disabled: !permissions.schedules.canEdit && schedules.length === 0,
												disabledTooltip:
													!permissions.schedules.canEdit && schedules.length === 0
														? `You don't have permissions to schedule this experiment${''}`
														: undefined,
												onClick: () => {
													onSchedule();
													close();
												},
											}
										: null,

									actions.includes('delete') || isNewExperiment
										? {
												id: '5',
												icon: 'delete',
												label: isNewExperiment ? 'Discard' : 'Delete',
												onClick: async () => {
													if (
														(await userConfirm({
															title: isNewExperiment ? 'Discard new Experiment' : 'Delete Experiment',
															message: isNewExperiment
																? `Do you really want to discard your new Experiment ${name ?? ''}?`
																: `Do you really want to delete ${experimentKey} ${name ?? ''}?`,
															actions: [
																{ value: 'cancel', label: 'Cancel' },
																{
																	value: 'confirm',
																	label: isNewExperiment ? 'Discard' : `Delete ${experimentKey}`,
																	variant: 'primary',
																},
															],
														})) === 'confirm'
													) {
														if (isNewExperiment) {
															history.replace('/experiments', 'redirect_after_delete');
														} else {
															try {
																await Services.experiments.deleteExperiment(experimentKey ?? '', 'UI_EDITOR');
																Snackbar.dark('Experiment deleted.', { toastId: 'experiment-deleted' });
																history.replace('/experiments', 'redirect_after_delete');
															} catch (err) {
																Snackbar.error('Experiment not deleted: ' + err.toString(), {
																	toastId: 'experiment-deleted',
																});
															}
														}
													}
												},
											}
										: null,
								].filter(Boolean) as ContextMenuItem[]
							}
							ItemRenderer={ContextMenuItemRenderer}
						/>
					</presets.dropdown.DropdownContentFrame>
				)}
			>
				{({ setRefElement, isOpen, setOpen }) => {
					return (
						<IconButton
							ref={setRefElement}
							type="chromelessWithBorderOnHover"
							disabled={isSubmitting}
							size="small"
							icon="dots"
							onClick={() => setOpen(!isOpen)}
						/>
					);
				}}
			</Dropdown>

			<Stack direction="horizontal" justifyContent="flex-end" alignItems="center" overflowX="hidden">
				{canSaveAsTemplate ? (
					<Flex direction="horizontal">
						<Button
							type="secondary"
							size="small"
							disabled={isSubmitting}
							data-cy="save-experiment-button"
							style={{
								borderTopRightRadius: 0,
								borderBottomRightRadius: 0,
								minWidth: '65px',
							}}
							onClick={submitForm}
						>
							{isSubmitting ? <LoadingIndicator /> : 'Save'}
						</Button>

						<Dropdown
							placement="bottom-end"
							renderDropdownContent={() => {
								let item = (
									<presets.dropdown.SingleChoiceListItemRenderer
										item={{
											id: '1',
											icon: 'template',
											label: 'Save as Template',
											disabled: !templatesEnabled,
										}}
										onClick={
											templatesEnabled
												? () => history.push(`/settings/templates/design/fromExperiment/${values.experimentKey}`)
												: undefined
										}
										prevItem={null}
										nextItem={null}
										index={0}
										isFirst
										isLast
									/>
								);
								if (!templatesEnabled) {
									item = (
										<Tooltip content={<LicenseTooltipContent />}>
											<div>{item}</div>
										</Tooltip>
									);
								}

								return (
									<presets.dropdown.DropdownContentFrame style={{ p: 'xxSmall' }}>
										{item}
									</presets.dropdown.DropdownContentFrame>
								);
							}}
						>
							{({ setRefElement, isOpen, setOpen }) => {
								return (
									<IconButton
										ref={setRefElement}
										disabled={isSubmitting}
										type="secondary"
										size="small"
										icon={isOpen ? 'triangle-up' : 'triangle-down'}
										style={{
											borderTopLeftRadius: 0,
											borderBottomLeftRadius: 0,
										}}
										onClick={() => setOpen(!isOpen)}
									/>
								);
							}}
						</Dropdown>
					</Flex>
				) : canSaveExperiment ? (
					<Button
						type="secondary"
						size="small"
						disabled={isSubmitting}
						data-cy="save-experiment-button"
						onClick={submitForm}
					>
						Save
					</Button>
				) : null}
			</Stack>
		</Subnav>
	);

	async function ensureSaved({ saveLabel }: { saveLabel: string }): Promise<boolean> {
		if (dirty || isNewExperiment) {
			if (
				(await userConfirm({
					title: 'Unsaved Changes',
					message: `You have unsaved changes. Do you want to save ${experimentKey} ${name ?? ''}?`,
					actions: [
						{ value: 'cancel', label: 'Cancel' },
						{
							value: 'save&run',
							label: saveLabel,
							variant: 'primary',
						},
					],
				})) === 'save&run'
			) {
				const saveResult = await saveExperiment(values);
				if (saveResult == null) {
					return false;
				}
				const [, saved] = saveResult;
				if (saved === null) {
					return false;
				}

				const experimentKey = saved.experimentKey;
				const experiment = await Services.experiments.fetchExperiment(experimentKey);

				setValues({ ...values, version: experiment.version });
				setTouched({});

				return true;
			} else {
				return false;
			}
		}
		return true;
	}

	async function onDuplicate(): Promise<void> {
		const executeAction = await ensureSaved({ saveLabel: 'Save & Duplicate' });
		if (executeAction) {
			const original = await Services.experiments.fetchExperiment(experimentKey);
			const duplicateResult: DuplicateExperimentResult = await duplicateExperimentConfirm(original);
			if (duplicateResult.result === 'cancel') {
				return;
			}

			const copyedExperimentFormData = await prepareCopyExperimentForm(
				experimentKey,
				duplicateResult,
				'UI_COPIED_EDITOR',
			);
			const saved = await Services.experiments.createOrUpdateExperiment(copyedExperimentFormData.initialValues);

			history.push(`/experiments/edit/${saved.experimentKey}/design`);
		}
	}

	async function onDownload(): Promise<void> {
		const executeAction = await ensureSaved({ saveLabel: 'Save & Download' });
		if (executeAction) {
			setShowDownloadModal(true);
		}
	}

	async function onBadge(): Promise<void> {
		const executeAction = await ensureSaved({ saveLabel: 'Save & Create Badge' });
		if (executeAction) {
			setShowBadgeModal(true);
		}
	}

	async function onSchedule(): Promise<void> {
		const executeAction = await ensureSaved({ saveLabel: 'Save & Schedule' });
		if (executeAction) {
			setShowScheduleModal(true);
		}
	}
}

function Divider(): ReactElement {
	return <div style={{ width: 1, height: 20, backgroundColor: theme.colors.neutral300 }} />;
}

type AdditionalProps = {
	tooltip?: string;
};

interface ContextMenuItem extends BasicListItem {
	disabledTooltip?: string;
	icon: IconType;
	label: string;
	onClick?: () => void;
}

const ContextMenuItemRenderer: ItemRenderer<ContextMenuItem, AdditionalProps> = ({
	index,
	prevItem,
	nextItem,
	isFirst,
	isLast,
	item,
}) => {
	const { onClick, disabledTooltip } = item;
	let renderItem = (
		<presets.dropdown.SingleChoiceListItemRenderer
			prevItem={prevItem}
			nextItem={nextItem}
			isFirst={isFirst}
			isLast={isLast}
			index={index}
			item={item}
			onClick={onClick}
		/>
	);

	if (disabledTooltip) {
		renderItem = (
			<Tooltip content={disabledTooltip}>
				<div>{renderItem}</div>
			</Tooltip>
		);
	}

	return renderItem;
};
