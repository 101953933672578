/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { dateInTimeZone, formatDateWithTime, formatDateWithTimeInTimeZone, timeZones } from 'utils/dateFns';
import TimezoneSelection from 'components/Select/Dropdown/presets/TimezoneSelection';
import { Flex, presets, Text } from '@steadybit/ui-components-lib';
import { ReactElement, ReactNode, useMemo } from 'react';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { useUser } from 'services/usersApi';
import { theme } from 'styles.v2/theme';
import { Stack } from 'components';
import cronstrue from 'cronstrue';

import { cronOption, onceOption, ScheduleType } from './EditSchedulesModal/types';
import { ScheduleContentBlock } from './EditSchedulesModal/EditSchedulesModal';
import { ConfigureScheduleProps } from './EditSchedulesModal/EditSchedule';
import CronExamples from './CronExamples';

export default function ScheduleCronTab({
	schedule,
	disabled,
	switchScheduleType,
	setScheduleUpdate,
	children,
}: ConfigureScheduleProps): ReactElement {
	const user = useUser();
	const browserDefaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const usersTimeZone = timeZones.find((t) => t.timeZone === (user.timeZone || browserDefaultTimezone)) || timeZones[0];

	const cron = schedule.cron ?? '';
	const cronParts = cron.split(' ');
	const timeZone = timeZones.find((t) => t.timeZone === (schedule.timezone || browserDefaultTimezone)) || timeZones[0];

	const readableCron: string | undefined = useMemo(() => {
		try {
			return cronstrue.toString(cron, { dayOfWeekStartIndexZero: false });
		} catch {
			return undefined;
		}
	}, [cron]);

	const readableCronUserTimeZone: string | undefined = useMemo(() => {
		try {
			const dateUserTimezone = dateInTimeZone(new Date(), usersTimeZone.timeZone);
			const dateScheduleTimeZone = dateInTimeZone(new Date(), timeZone.timeZone);
			const diffInMillis = dateUserTimezone.getTime() - dateScheduleTimeZone.getTime();
			const tzOffset = diffInMillis / 1000 / 3600;
			return cronstrue.toString(cron, { tzOffset, dayOfWeekStartIndexZero: false });
		} catch {
			return undefined;
		}
	}, [cron, timeZone, usersTimeZone]);

	const result = usePromise(() => {
		if (!readableCron) {
			return Promise.resolve(undefined);
		}
		return Services.schedulesApi.validateCronExpression({ cron, timeZone: timeZone?.timeZone });
	}, [cron, timeZone?.timeZone]);
	const isValidDateSelected = (result.value?.valid || false) && !result.loading && !result.error && !!readableCron;

	const cronNextRuns = result.value?.nextExecutions || [];
	const cronError = result.value?.error;
	const cronValid = isValidDateSelected;

	const widths: number[] = [0, 1, 2, 3, 4, 5, 6].map((i) => {
		const part = cronParts[i] || '';
		return Math.min(80, Math.max(44, 16 + 14 * part.length));
	});

	return (
		<>
			<ScheduleContentBlock
				title="Run this Experiment"
				description="Should the schedule run the experiment only once or recurrently, based on a cron quartz expression?"
				setting={
					<presets.dropdown.SingleChoiceButton
						items={[onceOption, cronOption]}
						selectedId={cronOption.id}
						disabled={disabled}
						size="small"
						style={{ width: '158px' }}
						onSelect={(id) => switchScheduleType(id as ScheduleType)}
					>
						{cronOption.label}
					</presets.dropdown.SingleChoiceButton>
				}
			>
				<Stack size="small" alignItems="center">
					<Stack alignItems="center">
						<Stack
							size="xSmall"
							alignItems="center"
							sx={{
								bg: 'neutral100',
								borderRadius: 8,
								width: 444,
							}}
						>
							<Stack direction="horizontal" size="xSmall" alignItems="center">
								{[0, 1, 2, 3, 4, 5, 6].map((i) => {
									const part = cronParts[i] || '';

									return (
										<input
											key={i}
											id={`cron-part-${i}`}
											type="text"
											value={part}
											disabled={disabled}
											autoComplete="off"
											onChange={(e) => {
												const newValue = e.target.value;
												if (newValue.endsWith(' ')) {
													const nextInput = document.getElementById(`cron-part-${i + 1}`);
													if (nextInput) {
														nextInput.focus();
													}
												} else if (!newValue) {
													const nextInput = document.getElementById(`cron-part-${i - 1}`);
													if (nextInput) {
														nextInput.focus();
													}
												}

												const newCronParts = [...cronParts];
												newCronParts[i] = newValue.trim();
												setScheduleUpdate({ ...schedule, cron: newCronParts.join(' ') });
											}}
											onKeyDown={(e) => {
												const caretPosition = e.currentTarget.selectionStart;
												if (caretPosition != null && e.key === 'ArrowLeft') {
													const cursorPositionAfterKey = caretPosition - 1;
													if (cursorPositionAfterKey === -1) {
														const prevInput = document.getElementById(`cron-part-${i - 1}`) as HTMLInputElement | null;
														if (prevInput) {
															prevInput.focus();
															prevInput.setSelectionRange(prevInput.value.length, prevInput.value.length);
														}
													}
												} else if (caretPosition != null && e.key === 'ArrowRight') {
													const cursorPositionAfterKey = caretPosition + 1;
													if (cursorPositionAfterKey > part.length) {
														const nextInput = document.getElementById(`cron-part-${i + 1}`) as HTMLInputElement | null;
														if (nextInput) {
															nextInput.focus();
															nextInput.setSelectionRange(0, 0);
														}
													}
												}

												if (e.key === 'Backspace') {
													const prevInput = document.getElementById(`cron-part-${i - 1}`);
													if (!part && prevInput) {
														prevInput.focus();
													}
												}
											}}
											onPaste={(e) => {
												try {
													const newParts = e.clipboardData.getData('text/plain').split(' ');
													const newCronParts = [...cronParts];
													newCronParts.splice(i, newParts.length, ...newParts);
													setScheduleUpdate({ ...schedule, cron: newCronParts.join(' ') });

													e.preventDefault();
												} catch {
													// ignore
												}
											}}
											style={{
												maxWidth: widths[i],
												fontFamily: 'monospace !important',
												backgroundColor: 'transparent',
												border: '1px solid ' + (part ? theme.colors.slate : theme.colors.neutral300),
												color: theme.colors.neutral800,
												textAlign: 'center',
												fontSize: '24px',
												fontWeight: 'regular',
												paddingLeft: '0px',
												paddingRight: '0px',
												paddingTop: '12px',
												paddingBottom: '12px',
												borderRadius: '4px',
												outline: 'none',
											}}
										/>
									);
								})}
							</Stack>

							<Stack direction="horizontal" size="xSmall" alignItems="flex-start">
								<Text type="code" neutral600 style={{ textAlign: 'center', width: widths[0] + 2 }}>
									second
								</Text>
								<Text type="code" neutral600 style={{ textAlign: 'center', width: widths[1] + 2 }}>
									minute
								</Text>
								<Text type="code" neutral600 style={{ textAlign: 'center', width: widths[2] + 2 }}>
									hour
								</Text>
								<Text type="code" neutral600 style={{ textAlign: 'center', width: widths[3] + 2 }}>
									day (month)
								</Text>
								<Text type="code" neutral600 style={{ textAlign: 'center', width: widths[4] + 2 }}>
									month
								</Text>
								<Text type="code" neutral600 style={{ textAlign: 'center', width: widths[5] + 2 }}>
									day (week)
								</Text>
								<Text type="code" neutral600 style={{ textAlign: 'center', width: widths[6] + 2 }}>
									year
								</Text>
							</Stack>
						</Stack>

						<Text type="small" neutral800 style={{ textAlign: 'center' }}>
							{cronValid === true || (cronValid === false && cron === '') ? (
								readableCronUserTimeZone ? (
									readableCronUserTimeZone
								) : (
									<>&nbsp;</>
								)
							) : cronValid === undefined ? (
								'validating...'
							) : cronError ? (
								cronError
							) : (
								'Invalid Quartz expression'
							)}
						</Text>
					</Stack>
				</Stack>
			</ScheduleContentBlock>

			<CronExamples />

			<Flex direction="horizontal" spacing="small" style={{ width: '100%' }}>
				<ScheduleTimezoneBlock
					title="Schedule Timezone"
					timezone={
						<TimezoneSelection
							selectedTimezone={timeZone.timeZone}
							selectTimezone={(timeZone) => {
								const match = timeZones.find((t) => timeZone === t.timeZone);
								if (match) {
									setScheduleUpdate({ ...schedule, timezone: match.timeZone });
								}
							}}
						/>
					}
					userReadableTimezone={isValidDateSelected ? readableCron : undefined}
					nextRuns={
						isValidDateSelected
							? cronNextRuns.map((d) => formatDateWithTimeInTimeZone(d, timeZone.timeZone))
							: undefined
					}
				/>

				<ScheduleTimezoneBlock
					title="Schedule converted to your Timezone"
					timezone={
						<Text type="smallStrong" neutral700 style={{ lineHeight: '42px' }}>
							{usersTimeZone.timeZone.replace('_', ' ')}
						</Text>
					}
					userReadableTimezone={isValidDateSelected ? readableCronUserTimeZone : undefined}
					nextRuns={isValidDateSelected ? cronNextRuns.map(formatDateWithTime) : undefined}
				/>
			</Flex>

			{children(cronValid ? undefined : 'Please specify a valid crontab expression')}
		</>
	);
}

function ScheduleTimezoneBlock({
	userReadableTimezone,
	timezone,
	nextRuns,
	title,
}: {
	userReadableTimezone: string | undefined;
	timezone: ReactNode;
	nextRuns?: string[];
	title: string;
}): ReactElement {
	return (
		<Stack
			size="xSmall"
			sx={{
				alignItems: 'stretch',
				p: 'small',
				background: 'neutral100',
				borderRadius: '8px',
				flex: '1',
			}}
		>
			<Text type="smallStrong" neutral600>
				{title}
			</Text>
			{timezone}
			<Text type="small">{userReadableTimezone ? userReadableTimezone : <>&nbsp;</>}</Text>
			<Text type="small">
				{nextRuns ? (
					<Flex direction="horizontal" spacing="small">
						<Text type="smallStrong">Next run:</Text>
						<Flex spacing="xxSmall">
							{nextRuns.slice(0, 3).map((nextRun) => (
								<Text key={nextRun} type="small">
									{nextRun}
								</Text>
							))}
							<Text type="small">...</Text>
						</Flex>
					</Flex>
				) : (
					<>&nbsp;</>
				)}
			</Text>
		</Stack>
	);
}
