/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react';
import { Button } from 'components';

import { Input } from './Integer';

interface PercentageProps {
	hasErrors?: boolean;
	disabled: boolean;
	max?: number;
	min?: number;
	value: string;
	setValue: (value: string) => void;
}
export default function Percentage({ value, min, max, disabled, hasErrors, setValue }: PercentageProps): ReactElement {
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Input
				value={value}
				onChange={(newValue) => {
					setValue(newValue + '');
				}}
				hasErrors={hasErrors}
				disabled={disabled}
				max={max}
				min={min}
				withUnit
			/>
			<Button
				variant="secondary"
				sx={{
					borderRadius: '0 4px 4px 0',
					'&:hover': {
						color: 'neutral700',
						background: 'linear-gradient(180deg, #FFFFFF 0%, #F4F7FB 100%)',
						cursor: 'default',
					},
				}}
			>
				%
			</Button>
		</div>
	);
}
