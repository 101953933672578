/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Flex } from '@steadybit/ui-components-lib';
import { EnvironmentSummaryVO } from 'ui-api';
import { ReactElement } from 'react';
import { Formik } from 'formik';

import SidebarPredicateModule from './SidebarPredicateModule';
import SidebarWrapper from './SidebarWrapper';
import { EnvironmentConfig } from './types';
import ResultView from './ResultView';

interface ReadOnlyEnvironmentDetailsProps {
	environment: EnvironmentSummaryVO;
}

export default function ReadOnlyEnvironmentDetails({ environment }: ReadOnlyEnvironmentDetailsProps): ReactElement {
	return (
		<Formik<EnvironmentConfig>
			initialValues={{
				name: environment.name,
				predicate: environment.predicate,
				teamIds: environment.teams.map((team) => team.id),
			}}
			onSubmit={() => {}}
		>
			<Flex
				direction="horizontal"
				style={{
					overflow: 'hidden',
					width: '100%',
					height: '100%',
				}}
			>
				<SidebarWrapper>
					<SidebarPredicateModule readonly isGlobalEnvironment={environment.global} />
				</SidebarWrapper>

				<ResultView />
			</Flex>
		</Formik>
	);
}
