/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ModalContentV2, ModalFooterV2, ModalHeaderV2, ModalOverlay, ModalV2, Tooltip } from 'components';
import { Button, Flex } from '@steadybit/ui-components-lib';
import { ExperimentVO, VariableVO } from 'ui-api';
import { Formik, useFormikContext } from 'formik';
import { ReactElement, useState } from 'react';
import { mergeAndSort } from 'utils/envVars';

import ExecutionOverrideTable from './ExecutionOverrideTable';
import ValidationHandler from './ValidationHandler';
import { ExperimentFormValues } from './types';

interface ExecuteWithOverridesModalProps {
	onExecute: (executionVariables: VariableVO[]) => Promise<void>;
	onClose: () => void;
}

export default function ExecuteWithOverridesModal({
	onExecute,
	onClose,
}: ExecuteWithOverridesModalProps): ReactElement {
	const { values } = useFormikContext<ExperimentFormValues>();
	const [variables, setVariables] = useState<VariableVO[]>([]);

	const used = mergeAndSort(values.variables, values.experimentVariables).filter(
		({ key }) => values.metadata?.variables[key],
	);

	const mergedVariables = mergeAndSort(used, variables || []);

	return (
		<Formik<ExperimentFormValues>
			initialValues={{
				...values,
				variables: [],
				experimentVariables: mergedVariables,
			}}
			// see <ValidationHandler /> for docs
			validateOnChange={false}
			validateOnBlur={false}
			enableReinitialize
			onSubmit={() => {}}
		>
			<>
				<ValidationHandler />

				<ModalOverlay open centerContent onClose={onClose}>
					<ModalV2 minHeight="64px" width="90vw" maxWidth="800px" withFooter>
						<ModalHeaderV2
							title="Run with Variable Overrides"
							subTitle="Override variable's values in this experiment, and run it with overriden variables immediately."
							onClose={onClose}
							px="large"
						/>

						<ModalContentV2 px="large">
							<ExecutionOverrideTable
								experiment={toExperiment(values)}
								variables={variables}
								usedVariables={used}
								disabled={false}
								occurances={values.metadata?.variables || {}}
								setVariables={(v) => setVariables(v || [])}
							/>
						</ModalContentV2>
						<ModalFooterV2 px="large">
							<Footer onClose={onClose} onExecute={() => onExecute(variables)} />
						</ModalFooterV2>
					</ModalV2>
				</ModalOverlay>
			</>
		</Formik>
	);
}

function toExperiment(value: ExperimentFormValues): ExperimentVO {
	return {
		...value,
		// fill up with values which are not used
		created: new Date(),
		createdBy: {
			username: '',
			name: '',
		},
		edited: new Date(),
		editedBy: {
			username: '',
			name: '',
		},
		_actions: value.actions,
		key: value.experimentKey,
		version: 0,
	};
}

interface FooterProps {
	onExecute: () => void;
	onClose: () => void;
}

function Footer({ onClose, onExecute }: FooterProps): ReactElement {
	const { errors } = useFormikContext<ExperimentFormValues>();
	const hasErrors = Object.keys(errors).length > 0;

	return (
		<Flex direction="horizontal" justify="end" spacing="medium" style={{ width: '100%' }}>
			<Button type="secondary" onClick={onClose}>
				Cancel
			</Button>
			<Tooltip content={hasErrors ? 'There are validation errors in your experiment.' : undefined}>
				<Button disabled={hasErrors} onClick={onExecute}>
					Run Experiment with Overrides
				</Button>
			</Tooltip>
		</Flex>
	);
}
