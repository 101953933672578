/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ButtonIcon,
	Link,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	TableSortLink,
} from 'components';
import { Box, Dropdown, Flex, ItemRenderer, Li, presets, Text, TextInput, Ul } from '@steadybit/ui-components-lib';
import { Notification } from 'components/Notifications/Notifications';
import TableLoadingRow from 'components/Table/TableLoadingRow';
import { IconDelete, IconUserAdd } from 'components/icons';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { Order, usePage } from 'utils/hooks/usePage';
import UserIcon from 'components/UserIcon/UserIcon';
import { useTenant } from 'tenancy/useTenant';
import { Services } from 'services/services';
import React, { ReactElement } from 'react';
import { useFormikContext } from 'formik';
import { isUserAdmin } from 'utils/user';
import { theme } from 'styles.v2/theme';
import { includes } from 'utils/string';
import { UserVO } from 'ui-api';

import TeamRoleSelect from './teamRoleSelect';
import { TeamFormValues } from './teamTypes';

const SORT_NAME_ASC: Order[] = [['name', 'asc', 'ignoreCase']];
const SORT_NAME_DESC: Order[] = [['name', 'desc', 'ignoreCase']];

export function TeamMembers({ teamId, disabled = false }: { teamId?: string; disabled?: boolean }): ReactElement {
	const formik = useFormikContext<TeamFormValues>();
	const [value, setValue] = React.useState('');

	const tenant = useTenant();
	const isAdmin = isUserAdmin(tenant.user);

	const page = usePage(`/${teamId}`, { size: 1000, sort: SORT_NAME_ASC });
	const [users] = useAsyncState(() => Services.users.fetchUsers(page.pageParams), [page]);
	const canAddMembers = !formik.errors.members;

	const isLoading = users.loading;

	function handleAddMember(value: string): void {
		if (!formik.values.members.find((m) => m.username === value)) {
			formik.setFieldValue('members', [...formik.values.members, { username: value, role: 'MEMBER' }]);
		}
	}

	const handleRoleChange = (user: UserVO, role: string): void => {
		const indexOfUser = formik.values.members.findIndex((m) => m.username === user.username);
		if (indexOfUser >= 0) {
			formik.setFieldValue(`members[${indexOfUser}].role`, role);
		}
	};

	const handleRemoveMember = (user: UserVO) => () => {
		const newMembers = formik.values.members.filter((m) => m.username !== user.username);
		formik.setFieldValue('members', newMembers);
	};

	const resolvedMembers: UserVO[] = [];
	const resolvedUsers: UserVO[] = [];
	if (users.value) {
		for (const user of users.value.content) {
			if (formik.values.members.find((m) => m.username === user.username)) {
				resolvedMembers.push(user);
			} else {
				resolvedUsers.push(user);
			}
		}
	}

	return (
		<Stack>
			{!canAddMembers && (
				<Notification
					content={{
						message: (
							<Text>
								Only users who are already part of a team (full members) can be added here. To invite someone who is not
								a full member, please{' '}
								{isAdmin ? (
									<Link href="https://steadybit.com/contact-us/" external>
										contact us
									</Link>
								) : (
									'contact your admin'
								)}
								.
							</Text>
						),
						level: 'warning',
					}}
				/>
			)}

			{!disabled && (
				<Dropdown
					renderDropdownContent={({ width, close }) => {
						const filteredUsers = resolvedUsers.filter((user) => {
							const matchesName = includes(user.name, value);
							const matchesMail = user.email ? includes(user.email, value) : false;
							return matchesName || matchesMail;
						});

						return (
							<presets.dropdown.DropdownContentFrame width={width} maxHeight="400px">
								{filteredUsers.length === 0 ? (
									<Box style={{ py: 'small' }}>
										<Text neutral600 style={{ padding: theme.space.small }}>
											No users matching your query.
										</Text>
									</Box>
								) : (
									<Ul<UserListItem, AdditionalProps>
										items={filteredUsers.map((user) => ({
											id: user.username,
											...user,
										}))}
										onSelect={(id) => {
											handleAddMember(id);
											setValue('');
											close();
										}}
										ItemRenderer={ListItem}
									/>
								)}
							</presets.dropdown.DropdownContentFrame>
						);
					}}
				>
					{({ setRefElement, isOpen, setOpen }) => (
						<TextInput
							ref={setRefElement}
							placeholder="Add existing members."
							highlighted={isOpen}
							data-private
							value={value}
							onClick={() => setOpen(!isOpen)}
							onChange={setValue}
						/>
					)}
				</Dropdown>
			)}

			<Table width={'100%'} data-cy="team-table">
				<TableHead>
					<TableRow>
						<TableHeadCell>
							<TableSortLink
								sort={page.getDirection(SORT_NAME_ASC, SORT_NAME_DESC)}
								to={page.toggleSort(SORT_NAME_ASC, SORT_NAME_DESC).toString()}
							>
								Name
							</TableSortLink>
						</TableHeadCell>
						<TableHeadCell>Role</TableHeadCell>
						<TableHeadCell />
					</TableRow>
				</TableHead>
				<TableBody>
					{isLoading ? (
						<>
							<TableLoadingRow numColumns={2} />
							<TableLoadingRow numColumns={2} />
							<TableLoadingRow numColumns={2} />
						</>
					) : (
						resolvedMembers.map((user) => (
							<MemberRow
								key={user.username}
								user={user}
								role={formik.values.members.find((m) => m.username === user.username)?.role ?? undefined}
								disabled={disabled}
								onRoleChange={(role) => handleRoleChange(user, role)}
								onRemove={handleRemoveMember(user)}
							/>
						))
					)}
				</TableBody>
			</Table>
		</Stack>
	);
}

type AdditionalProps = object;

interface UserListItem extends UserVO {
	id: string;
}

const ListItem: ItemRenderer<UserListItem, AdditionalProps> = ({ item, onClick }) => {
	const { name, email, teams } = item;
	const isFullMember = teams.length > 0;

	return (
		<Li onClick={() => onClick?.(item.id)}>
			<Flex
				direction="horizontal"
				spacing="xxSmall"
				justify="spread"
				style={{
					py: 'xSmall',
					px: 'none',
					onHover: {
						cursor: 'pointer',
						backgroundColor: theme.colors.neutral100,
					},
				}}
			>
				<Flex direction="horizontal" spacing="xxSmall" style={{ pointerEvents: 'none' }} data-private>
					<IconUserAdd color="slate" />
					<Text type="smallStrong" neutral800>
						{name}
					</Text>
					{email && (
						<Text type="small" neutral600>
							({email})
						</Text>
					)}
				</Flex>
				{isFullMember && (
					<Text type="small" neutral600>
						Already a full member
					</Text>
				)}
			</Flex>
		</Li>
	);
};

interface MemberRowProps {
	disabled?: boolean;
	role?: string;
	user: UserVO;
	onRoleChange: (r: string) => void;
	onRemove: () => void;
}

function MemberRow({ user, role, disabled, onRoleChange, onRemove }: MemberRowProps): ReactElement {
	return (
		<TableRow hoverable={true}>
			<TableDataCell data-private>
				<Flex direction="horizontal" spacing="xSmall" align="center" data-private>
					<UserIcon src={user.pictureUrl} size="medium" />
					<Flex>
						<Text>{user.name}</Text>
						<Text type="smallStrong" neutral600 textEllipsis data-private>
							{user.email}
						</Text>
					</Flex>
				</Flex>
			</TableDataCell>
			<TableDataCell>{!disabled ? <TeamRoleSelect value={role} onChange={onRoleChange} /> : role}</TableDataCell>
			<TableDataCell justifyContent={'flex-end'}>
				{!disabled ? (
					<ButtonIcon muted onClick={onRemove}>
						<IconDelete color="neutral600" />
					</ButtonIcon>
				) : null}
			</TableDataCell>
		</TableRow>
	);
}
