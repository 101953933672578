/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ExperimentScheduleSummaryVO,
	ExperimentScheduleVO,
	PagedModel,
	SteadybitEventVO,
	UpsertExperimentScheduleVO,
	ValidateCronExpressionRequest,
	ValidateCronExpressionResponse,
} from 'ui-api';
import { createResult, DataStreamResult, loadingResult } from 'utils/hooks/stream/result';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { useObservable } from 'utils/hooks/useObservable';
import { PageParams } from 'utils/hooks/usePage';
import { from } from 'rxjs';
import axios from 'axios';

import { EventsApi } from './eventsApi';
import { useTeam } from './useTeam';

export class SchedulesApi {
	private eventsApi: EventsApi;

	constructor(events: EventsApi) {
		this.eventsApi = events;
	}

	private async fetchSchedules(
		page: PageParams,
		teamId: string,
		experimentKey?: string,
	): Promise<PagedModel<ExperimentScheduleSummaryVO>> {
		const params = page.appendTo(new URLSearchParams());
		if (teamId) {
			params.append('teamId', teamId);
		}
		if (experimentKey) {
			params.append('experimentKey', experimentKey);
		}
		return (await axios.get('/ui/experiments/schedules', { params })).data;
	}

	async scheduleExperiment(schedule: UpsertExperimentScheduleVO): Promise<ExperimentScheduleVO> {
		return (await axios.post('/ui/experiments/schedules', schedule)).data;
	}

	async deleteExperimentSchedule(id: string): Promise<void> {
		await axios.delete(`/ui/experiments/schedules/${id}`);
	}

	async validateCronExpression(data: ValidateCronExpressionRequest): Promise<ValidateCronExpressionResponse> {
		const result = (await axios.post<ValidateCronExpressionResponse>('/ui/experiments/schedules/validate/cron', data))
			.data;
		if (result != undefined && result.nextExecutions != undefined) {
			for (let i = 0; i < result.nextExecutions.length; i++) {
				result.nextExecutions[i] = new Date(result.nextExecutions[i]);
			}
		}
		return result;
	}

	async getSchedulesPermissions(teamId: string): Promise<string[]> {
		return (await axios.get(`/ui/experiments/schedules/permissions/${teamId}`)).data;
	}

	useSchedules$(experimentKey?: string): DataStreamResult<PagedModel<ExperimentScheduleSummaryVO>> {
		const page = PageParams.of({
			number: 0,
			size: 100_000,
			order: [{ property: 'experimentKey', direction: 'ASC' }],
		});
		const team = useTeam();

		return useObservable(
			() =>
				this.eventsApi.events$.pipe(
					filter(
						(event) =>
							event.type === 'schedule.created' ||
							event.type === 'schedule.deleted' ||
							event.type === 'schedule.updated',
					),
					startWith(undefined),
					switchMap((event: SteadybitEventVO | undefined) => {
						const dataStream = from(this.fetchSchedules(page, team.id, experimentKey));
						// when there is no event, it is the initial load. For the initial load, we want to have a loading state while loading
						if (!event) {
							return dataStream.pipe(
								map((res) => createResult(res)),
								startWith(loadingResult),
							);
						}
						// for all following updates, we just replace the data when loaded, no loading state
						return dataStream.pipe(map((res) => createResult(res)));
					}),
				),
			[team.id, experimentKey],
		);
	}
}
