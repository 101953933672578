/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, ChunkLUT, Chunks, Colors, Dropdown, presets, TextInput } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';

import VariablesAndPlaceholders from './VariablesAndPlaceholders';
import useChunkLUT from './useChunkLUT';

interface OptionType {
	label: string;
	value: string;
}

interface StringOptionsProps {
	optionsOnly?: boolean;
	options: OptionType[];
	hasErrors?: boolean;
	required?: boolean;
	disabled: boolean;
	value: string;
	setValue: (value: string | undefined) => void;
}

export default function StringOptions({
	optionsOnly = false,
	required = true,
	hasErrors,
	disabled,
	options,
	value,
	setValue,
}: StringOptionsProps): ReactElement {
	const EMPTY_OPTION = {
		label: '[Select an option]',
		value: '',
	};
	const resolvedOption = !required && !value ? EMPTY_OPTION : options.find((option) => option.value === value);
	const chunkLUT: ChunkLUT = useChunkLUT();

	if (optionsOnly) {
		return (
			<Dropdown
				renderDropdownContent={({ width, close }) => (
					<DropDownContent
						options={required ? options : [EMPTY_OPTION, ...options]}
						resolvedOption={resolvedOption}
						width={width}
						selectItem={(value) => {
							setValue(value || undefined);
							close();
						}}
					/>
				)}
			>
				{({ setRefElement, isOpen, setOpen }) => {
					return (
						<Button
							ref={setRefElement}
							withRightIcon={isOpen ? 'arrow-drop-up' : 'arrow-drop-down'}
							disabled={disabled}
							type="secondary"
							style={{
								width: '100%',
								outline: hasErrors ? '2px solid ' + Colors.coral : isOpen ? '2px solid ' + Colors.slate : '',
								color: value ? Colors.slate : undefined,
								onHover: {
									color: Colors.neutral000,
								},
							}}
							onClick={() => setOpen(!isOpen)}
							data-cy="text-field-with-highlighting"
						>
							{resolvedOption ? resolvedOption.label : <Chunks value={value} chunkLUT={chunkLUT} />}
						</Button>
					);
				}}
			</Dropdown>
		);
	}

	return (
		<Dropdown
			renderDropdownContent={({ width, close }) => (
				<DropDownContent
					resolvedOption={resolvedOption}
					options={options}
					width={width}
					selectItem={(value) => {
						setValue(value);
						close();
					}}
				/>
			)}
		>
			{({ setRefElement, isOpen, setOpen }) => (
				<TextInput
					ref={setRefElement}
					highlightedChunkLUT={chunkLUT}
					highlighted={isOpen}
					disabled={disabled}
					errored={hasErrors}
					value={value}
					onChange={setValue}
					onFocus={() => {
						setOpen(true);
					}}
					data-cy="text-field-with-highlighting"
				/>
			)}
		</Dropdown>
	);
}

interface DropDownContentProps {
	resolvedOption?: OptionType | undefined;
	options: OptionType[];
	width: string;
	selectItem: (value: string) => void;
}

function DropDownContent({ width, resolvedOption, options, selectItem }: DropDownContentProps): ReactElement {
	return (
		<presets.dropdown.DropdownContentFrame width={width} maxHeight="350px">
			<presets.dropdown.SingleChoiceList
				items={options.map(({ label, value }) => ({
					id: value,
					label,
					isSelected: value === resolvedOption?.value,
				}))}
				withKeyboardNavigation
				onSelect={selectItem}
			/>
			<VariablesAndPlaceholders width={width} selectItem={selectItem} />
		</presets.dropdown.DropdownContentFrame>
	);
}
