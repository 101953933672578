/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, Colors, Dropdown, IconButton, presets, utils } from '@steadybit/ui-components-lib';
import { ReactElement, useEffect, useState } from 'react';
import { roundToTwoDecimals } from 'utils/numberFn';
import { Stack, Text, Tooltip } from 'components';
import { ExperimentLaneVO } from 'ui-api';
import { seconds } from 'utils/time';
import { useField } from 'formik';

interface ZoomControlsProps {
	pxPerSecond: number;
	setPxPerSecond: (pxPerSecond: number) => void;
}

export default function ZoomControls({ pxPerSecond, setPxPerSecond }: ZoomControlsProps): ReactElement {
	const [lanesField] = useField<ExperimentLaneVO[]>({ name: 'lanes' });
	const { value: lanes } = lanesField;
	const [maxWidthIsRestricted, setMaxWidthIsRestricted] = useState<boolean>(false);

	// The following effect is a safeguard to prevent the a too big canvas causing the browser performance to degrade.
	const maxLaneDuration = getMaxDuration(lanes);
	useEffect(() => {
		let newMaxWidthIsRestricted = false;
		const resultingWidth = maxLaneDuration * pxPerSecond;
		if (resultingWidth >= 100_000) {
			newMaxWidthIsRestricted = true;
		}
		if (resultingWidth > 100_000) {
			const neededZoom = 100_000 / maxLaneDuration;
			if (neededZoom !== pxPerSecond) {
				setPxPerSecond(neededZoom);
			}
		} else if (!maxWidthIsRestricted && !newMaxWidthIsRestricted && pxPerSecond < 0.5) {
			setPxPerSecond(0.5);
		}

		if (newMaxWidthIsRestricted !== maxWidthIsRestricted) {
			setMaxWidthIsRestricted(newMaxWidthIsRestricted);
		}
	}, [pxPerSecond, maxLaneDuration, maxWidthIsRestricted]);

	return (
		<Tooltip
			content={
				maxWidthIsRestricted ? (
					<Text variant="smallStrong" maxWidth={400}>
						The experiment duration is too long ({utils.toDuration(seconds(maxLaneDuration).getMillis())}) , so the
						maximum zoom level is limited to {Math.round(10 * pxPerSecond)}%.
					</Text>
				) : undefined
			}
		>
			<Stack
				direction="horizontal"
				size="none"
				sx={{
					position: 'absolute',
					left: '27px',
					bottom: '18px',
				}}
			>
				<IconButton
					type="secondary"
					size="small"
					onClick={() => setPxPerSecond(Math.max(0.5, Math.floor(pxPerSecond - 1)))}
					disabled={pxPerSecond <= 0.5}
					style={{
						borderTopRightRadius: 0,
						borderBottomRightRadius: 0,
						padding: '16px',
					}}
					icon="minus"
				/>

				<Dropdown
					placement="top"
					renderDropdownContent={({ close }) => (
						<DropDownContent
							selectItem={(id) => {
								if (id === 'zoom-to-fit') {
									if (lanes.length === 0) {
										return;
									}
									let workspaceWidth = document
										.getElementById('experiment.explorer.wrapper')
										?.getBoundingClientRect().width;
									if (workspaceWidth === undefined) {
										return;
									}

									workspaceWidth -= 24; // lanes start with this indicator we have to take it into account

									const maxDuration = getMaxDuration(lanes);
									const zoomToFit = workspaceWidth / (maxDuration * pxPerSecond);

									const newPxPerSecond = roundToTwoDecimals(pxPerSecond * zoomToFit);
									setPxPerSecond(Math.max(0.5, Math.min(20, newPxPerSecond)));
								} else {
									setPxPerSecond(Number(id));
								}

								close();
							}}
						/>
					)}
				>
					{({ setRefElement, isOpen, setOpen }) => {
						return (
							<Button
								ref={setRefElement}
								type="chromeless"
								withRightIcon={isOpen ? 'arrow-drop-up' : 'arrow-drop-down'}
								style={{
									minWidth: '75px',
									height: '34px',
									borderRadius: 'none',
									borderRight: 'none',
									borderLeft: 'none',
									borderTop: '1px solid',
									borderBottom: '1px solid',
									borderColor: Colors.neutral300,
									padding: '0 6px',
									backgroundColor: Colors.neutral000,
								}}
								onClick={() => setOpen(!isOpen)}
							>
								{Math.round(10 * pxPerSecond) + '%'}
							</Button>
						);
					}}
				</Dropdown>

				<IconButton
					type="secondary"
					size="small"
					onClick={() => setPxPerSecond(Math.min(20, Math.floor(Math.round(pxPerSecond) + 1)))}
					disabled={pxPerSecond >= 20}
					icon="plus"
					style={{
						borderTopLeftRadius: 0,
						borderBottomLeftRadius: 0,
						padding: '16px',
					}}
				/>
			</Stack>
		</Tooltip>
	);
}
interface DropDownContentProps {
	selectItem: (value: string) => void;
}

function DropDownContent({ selectItem }: DropDownContentProps): ReactElement {
	return (
		<presets.dropdown.DropdownContentFrame width="120px">
			<presets.dropdown.SingleChoiceList
				items={[
					{
						id: '20',
						label: '200%',
					},
					{
						id: '15',
						label: '150%',
					},
					{
						id: '12.5',
						label: '125%',
					},
					{
						id: '10',
						label: '100%',
					},
					{
						id: '7.5',
						label: '75%',
					},
					{
						id: '5',
						label: '50%',
					},
					{
						id: '1',
						label: '10%',
					},
					{
						id: '0.5',
						label: '5%',
					},
					{
						id: 'zoom-to-fit',
						label: 'Zoom to fit',
					},
				]}
				withKeyboardNavigation
				onSelect={selectItem}
			/>
		</presets.dropdown.DropdownContentFrame>
	);
}

function getMaxDuration(lane: ExperimentLaneVO[] | ExperimentLaneVO): number {
	if (lane instanceof Array) {
		return lane.reduce((max, lane) => Math.max(max, getMaxDuration(lane)), 0);
	}
	return lane.steps.reduce((prev, step) => {
		return prev + (utils.convertDurationToSeconds(step.parameters?.duration) || 10);
	}, 0);
}
