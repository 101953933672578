/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Container, ContainerProps, RouterButton, Stack, Text, Tooltip } from 'components';
import { IncidentBadgeWidget } from 'pages/dashboard/components/IncidentBadgeWidget';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import useGlobalPermissions from 'services/useGlobalPermissions';
import { Colors, Flex } from '@steadybit/ui-components-lib';
import { IconExperiment } from 'components/icons';
import { useUser } from 'services/usersApi';

import { ReactComponent as ImageUfo } from './svg/ufo.svg';
import ExtensionsWidget from './ExtensionsWidget';
import { ampli } from '../../../ampli';
import CreateBox from './CreateBox';

export function GetStartedWidget(props: ContainerProps): ReactElement {
	return (
		<Container {...props} sx={{ bg: 'neutral200', borderRadius: 2, position: 'relative', pb: 'xLarge' }}>
			<Container sx={{ position: 'absolute', bottom: -6, right: 0 }}>
				<ImageUfo />
			</Container>
			<Stack size={0} direction={'vertical'} ml={49} mr={18} mt={'xLarge'}>
				<Stack size={'medium'} direction={'horizontal'} justifyContent={'flex-start'}>
					<Text variant={'small'} color="neutral700" fontSize={'13px'} fontWeight={500} lineHeight={'150%'}>
						Get Started With Steadybit
					</Text>
				</Stack>

				<Stack size={'medium'} direction={'horizontal'} justifyContent={'flex-start'}>
					<Text variant={'smallStrong'} color="neutral700" fontSize={'32px'} lineHeight={'130%'}>
						Start Experimenting Right Away <br />
						and Become More Resilient.
					</Text>
				</Stack>

				<HeaderCards />
			</Stack>
		</Container>
	);
}

function HeaderCards(): ReactElement {
	const user = useUser();
	const permissions = useGlobalPermissions();

	return (
		<Flex direction="horizontal" wrap spacing="medium" style={{ overflow: 'hidden' }}>
			<CreateBox
				icon={IconExperiment}
				header={
					<span>
						Hey <span data-private>{user.name}</span>,
					</span>
				}
				text={`Steadybit already discovered your system. Let's create our first Chaos Engineering experiment to verify how your system reacts!${''}`}
				width={'fit-content'}
				maxWidth={470}
				height={220}
			>
				<Tooltip
					content={
						!permissions.experiments.canCreate
							? 'You need to be a member of the Team to create new experiments.'
							: undefined
					}
				>
					<RouterButton
						onClick={() => ampli.dashboardHeroTriggered({ dashboard_hero_action: 'create_experiment' })}
						disabled={!permissions.experiments.canCreate}
						to="/experiments/start"
						variant={'primary'}
						width={170}
					>
						Create Experiment
					</RouterButton>
				</Tooltip>
			</CreateBox>

			<Container sx={{ zIndex: 1 }}>
				<AutoCarousel items={[<IncidentBadgeWidget key="1" />, <ExtensionsWidget key="2" />]} />
			</Container>
		</Flex>
	);
}

interface AutoCarouselProps {
	items: ReactNode[];
}

function AutoCarousel({ items }: AutoCarouselProps): ReactElement {
	const [marginLeft, setMarginLeft] = useState(0);
	useEffect(() => {
		const timeout = setTimeout(() => {
			setMarginLeft(marginLeft === 0 ? -470 : 0);
		}, 10_000);
		return () => clearTimeout(timeout);
	}, [marginLeft]);

	return (
		<Container
			sx={{
				position: 'relative',
				width: 470,
				overflow: 'hidden',
				borderRadius: 4,
			}}
		>
			<Container
				sx={{
					display: 'flex',
					width: 'fit-content',
					marginLeft,
					transition: 'margin-left 300ms',
				}}
			>
				{items}
			</Container>

			<Flex
				direction="horizontal"
				justify="center"
				spacing="xxSmall"
				style={{ position: 'absolute', left: 0, right: 0, bottom: 12 }}
			>
				<Indicator active={marginLeft === 0} onClick={() => setMarginLeft(0)} />
				<Indicator active={marginLeft !== 0} onClick={() => setMarginLeft(-470)} />
			</Flex>
		</Container>
	);
}

function Indicator({ active, onClick }: { active: boolean; onClick: () => void }): ReactElement {
	return (
		<div
			style={{
				height: '8px',
				width: active ? '24px' : '8px',
				borderRadius: 4,
				backgroundColor: active ? Colors.slate : Colors.neutral300,
				cursor: active ? undefined : 'pointer',
			}}
			onClick={active ? undefined : onClick}
		/>
	);
}
