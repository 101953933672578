/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import AgentInstallBanner from 'components/AgentInstallBanner/AgentInstallBanner';
import { Container, Heading } from 'components';
import { ReactElement, useEffect } from 'react';

import { LastEditetExperimentsWidget } from './components/LastEditedExperimentsWidget';
import { LastExecutionsWidget } from './components/LastExecutionsWidget';
import { TeamActivitiesWidget } from './components/TeamActivitiesWidget';
import { GetStartedWidget } from './components/GetStartedWidget';
import { DiscoveryWidget } from './components/DiscoveryWidget';
import { ampli } from '../../ampli';

interface DashboardProps {
	renderAgentInstallBanner?: boolean;
}

export default function Dashboard({ renderAgentInstallBanner }: DashboardProps): ReactElement {
	useEffect(() => {
		ampli.dashboardViewed({ resume_agent_setup_shown: renderAgentInstallBanner ?? false });
	}, []);
	return (
		<Container
			maxWidth={1440}
			bg={'neutral050'}
			sx={{
				boxShadow: '0 0 10000px 10000px #FAFBFD', // set background color of parent: neutral050
			}}
			mx="auto"
		>
			{renderAgentInstallBanner && (
				<Container display={'flex'} flexWrap={'wrap'} mx={'xxLarge'} mt={24}>
					<AgentInstallBanner sx={{ width: '100%' }} />
				</Container>
			)}
			<Container display={'flex'} flexWrap={'wrap'}>
				<GetStartedWidget flex={'1 1 448px'} mx={'xxLarge'} mt={24} />
			</Container>
			<Heading mt={24} mb={12} ml={'xxLarge'} variant={'medium'} color={'neutral800'}>
				Dashboard
			</Heading>
			<Container display={'flex'} flexWrap={'wrap'} sx={{ gap: 'small' }} maxWidth={1312} mx="xxLarge">
				<DiscoveryWidget flex={'1 1 520px'} />
				<TeamActivitiesWidget flex={'1 1 520px'} />
				<LastExecutionsWidget flex={'1 1 520px'} />
				<LastEditetExperimentsWidget flex={'1 1 520px'} />
			</Container>
		</Container>
	);
}
