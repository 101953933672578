/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { TemplateMetadataVO, TemplateVO, VariableVO } from 'ui-api';
import { useStableInstance } from 'utils/hooks/useStableInstance';
import { useCallback, useEffect } from 'react';
import { Services } from 'services/services';
import { useFormikContext } from 'formik';
import { debounce } from 'lodash';

import { ExperimentFormValues } from './types';

/**
 * We want to have the backend the only source of truth for extracting variables and placeholders.
 * Since some places rely on the metadata, I put this into a separate component and enrich the form with it.
 */
export default function MetadataEnrichment(): null {
	const { values, setFieldValue } = useFormikContext<ExperimentFormValues>();

	const environmentVariablesResult = Services.environments.useEnvironmentVariables$(values.environmentId);
	const environmentVariables = environmentVariablesResult.value?.content;

	const extractMetadata = useCallback(
		debounce(
			async (v: ExperimentFormValues, envVars: VariableVO[]) => {
				const metadata: TemplateMetadataVO = await Services.templatesApi.getTemplateMetadata(
					toTemplateVO(v),
					v.environmentId,
				);
				setFieldValue('metadata', metadata);

				const variables = v.experimentVariables || [];
				const newVariables: string[] = Object.keys(metadata.variables).filter(
					(used) =>
						!variables.find((variable) => variable.key === used) && !envVars.find((variable) => variable.key === used),
				);

				setFieldValue('experimentVariables', [...variables, ...newVariables.map((key) => ({ key, value: '' }))]);

				setFieldValue('variables', envVars);
			},
			200,
			{ leading: true },
		),
		[],
	);

	const [stableId] = useStableInstance<ExperimentFormValues>({
		...values,
		metadata: undefined,
		variables: [],
		experimentVariables: [],
	});
	const [stableEnvVarId, stableEnvVars] = useStableInstance<VariableVO[] | undefined>(environmentVariables);
	useEffect(() => {
		if (!stableEnvVars) {
			return;
		}
		extractMetadata(values, stableEnvVars);
	}, [stableId, stableEnvVarId, extractMetadata]);

	return null;
}

function toTemplateVO(value: ExperimentFormValues): TemplateVO {
	return {
		...value,
		// fill up with values which are not used
		_actions: value.actions,
		id: '',
		templateDescription: '',
		experimentName: value.name,
		created: new Date(),
		createdBy: {
			username: '',
			name: '',
		},
		edited: new Date(),
		editedBy: {
			username: '',
			name: '',
		},
		placeholders: [],
		hidden: false,
		version: 0,
	};
}
