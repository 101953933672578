/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	BasicListItem,
	Button,
	Colors,
	Dropdown,
	Flex,
	IconButton,
	IconType,
	ItemRenderer,
	presets,
	Ul,
} from '@steadybit/ui-components-lib';
import { useExperimentExecutionTrigger } from 'pages/experiments/useExperimentExecutionTrigger';
import { LoadingIndicator, Snackbar, Tooltip, userConfirm } from 'components';
import { ReactElement, useState } from 'react';
import { Services } from 'services/services';
import { useFormikContext } from 'formik';
import { VariableVO } from 'ui-api';
import { isEmpty } from 'lodash';

import ExecuteWithOverridesModal from './ExecuteWithOverridesModal';
import { saveExperiment } from './ExperimentEditorFormHandler';
import { ExperimentFormValues } from './types';
import { validate } from './ValidationHandler';

interface HeaderButtonProps {
	disabled?: boolean;
}

export default function RunExperimentButton({ disabled }: HeaderButtonProps): ReactElement {
	const [showOverrideVariablesModal, setShowOverrideVariablesModal] = useState(false);
	const formik = useFormikContext<ExperimentFormValues>();
	const { experimentKey, name } = formik.values;
	const { touched } = formik;
	const dirty = Object.keys(touched).length > 0;

	const handleRun = useExperimentExecutionTrigger();
	const [isRunning, setIsRunning] = useState(false);

	const isNewExperiment = !experimentKey;

	const hasAnyVariable = Object.keys(formik.values.metadata?.variables ?? {}).length > 0;

	const checkIfRun = async (saveTitle: string): Promise<boolean> => {
		if (isRunning) {
			return false;
		}
		setIsRunning(true);
		let runExperiment = false;
		let values = formik.values;
		let experimentKey = values.experimentKey;

		if (dirty || isNewExperiment) {
			const userSelection = await userConfirm({
				title: 'Unsaved Changes',
				message: `You have unsaved changes. Do you want to save ${experimentKey} ${name ?? ''}?`,
				actions: [
					{ value: 'cancel', label: 'Cancel' },
					{
						value: 'save&run',
						label: saveTitle,
						variant: 'primary',
					},
				],
			});
			if (userSelection === 'save&run') {
				const saveResult = await saveExperiment(values);
				if (saveResult === null) {
					setIsRunning(false);
					return false;
				}
				const [, saved] = saveResult;
				if (saved === null) {
					setIsRunning(false);
					return false;
				}
				experimentKey = saved.experimentKey;
				const experiment = await Services.experiments.fetchExperiment(experimentKey);
				values = { ...values, ...experiment, experimentKey };

				formik.setValues(values);
				formik.setTouched({});

				runExperiment = true;
			}
		} else {
			runExperiment = true;
		}

		if (!runExperiment) {
			setIsRunning(false);
			return false;
		}

		const errors = await validate(values);
		setIsRunning(false);
		if (!isEmpty(errors)) {
			Snackbar.error('Experiment not started, there are errors.', { toastId: 'experiment-run' });
			formik.setErrors(errors);
			return false;
		}
		return true;
	};

	return (
		<Flex direction="horizontal">
			{showOverrideVariablesModal && (
				<ExecuteWithOverridesModal
					onExecute={async (executionVariables: VariableVO[]) => {
						await handleRun(experimentKey, {
							redirectToEditorOnRunFailure: false,
							executionSource: 'UI_EXPERIMENT_EDITOR',
							executionVariables,
						});
					}}
					onClose={() => setShowOverrideVariablesModal(false)}
				/>
			)}

			{/* This is not the nicest way, but everything other would clutter the code too much */}
			{/* It's a good enough approach to have only one place of all this magic */}
			<button
				id="execute-experiment-shadow-button"
				type="button"
				style={{ display: 'none' }}
				onClick={async () => {
					setShowOverrideVariablesModal(await checkIfRun('Save'));
				}}
			/>
			<Button
				type="primaryRun"
				withLeftIcon={isRunning ? undefined : 'play'}
				disabled={disabled || isRunning}
				style={{ minWidth: '175px', width: 'fit-content', borderTopRightRadius: '0', borderBottomRightRadius: '0' }}
				onClick={async () => {
					if (await checkIfRun('Save & Run')) {
						await handleRun(experimentKey, {
							redirectToEditorOnRunFailure: false,
							executionSource: 'UI_EXPERIMENT_EDITOR',
							executionVariables: [],
						});
					}
				}}
				data-cy="run"
			>
				{isRunning ? (
					<Flex direction="horizontal" justify="center" style={{ width: '100%' }}>
						<LoadingIndicator />
					</Flex>
				) : (
					'Run Experiment'
				)}
			</Button>

			<Dropdown
				placement="bottom-end"
				renderDropdownContent={({ close }) => (
					<presets.dropdown.DropdownContentFrame style={{ backgroundColor: Colors.neutral000 }}>
						<Ul<ContextMenuItem, AdditionalProps>
							items={[
								{
									id: '1',
									icon: 'function',
									label: 'Run with variables override',
									disabledTooltip: hasAnyVariable ? undefined : 'There are no variables used in this experiment',
									disabled: !hasAnyVariable,
									onClick: async () => {
										close();
										document.getElementById('execute-experiment-shadow-button')?.click();
									},
								},
							]}
							ItemRenderer={ContextMenuItemRenderer}
						/>
					</presets.dropdown.DropdownContentFrame>
				)}
			>
				{({ setRefElement, isOpen, setOpen }) => {
					return (
						<IconButton
							ref={setRefElement}
							type="primaryRun"
							icon={isOpen ? 'arrow-drop-up' : 'arrow-drop-down'}
							disabled={disabled || isRunning}
							style={{
								borderTopLeftRadius: '0',
								borderBottomLeftRadius: '0',
								borderLeft: '1px solid ' + Colors.purple400,
							}}
							onClick={() => setOpen(!isOpen)}
						/>
					);
				}}
			</Dropdown>
		</Flex>
	);
}

type AdditionalProps = object;

interface ContextMenuItem extends BasicListItem {
	disabledTooltip?: string;
	icon: IconType;
	label: string;
	onClick?: () => void;
}

const ContextMenuItemRenderer: ItemRenderer<ContextMenuItem, AdditionalProps> = ({
	prevItem,
	nextItem,
	isFirst,
	isLast,
	index,
	item,
}) => {
	const { disabledTooltip } = item;

	let renderItem = (
		<presets.dropdown.SingleChoiceListItemRenderer
			prevItem={prevItem}
			nextItem={nextItem}
			isFirst={isFirst}
			isLast={isLast}
			index={index}
			item={item}
			onClick={item.onClick}
		/>
	);

	if (disabledTooltip) {
		renderItem = (
			<Tooltip content={disabledTooltip}>
				<div>{renderItem}</div>
			</Tooltip>
		);
	}

	return renderItem;
};
